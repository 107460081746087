<template>
    <div class="registration">
        <section class="first-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div class="h2 text-center mt-100 mb-5">
                            {{ $t("Új vásárló regisztrálása") }}
                        </div>
                        <form v-on:submit.prevent="doRegistration">
                            <div class="d-flex mb-4">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        value="0"
                                        id="usertype-1"
                                        name="usertype"
                                        v-model="model.billing.is_company"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="usertype-1"
                                    >
                                        {{ $t("Magánszemély") }}
                                    </label>
                                </div>
                                <div class="form-check mx-3">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        value="1"
                                        id="usertype-2"
                                        name="usertype"
                                        v-model="model.billing.is_company"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="usertype-2"
                                    >
                                        {{ $t("Cég") }}
                                    </label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-6">
                                    <div
                                        class="form-floating mb-2"
                                        id="firstname"
                                    >
                                        <input
                                            type="text"
                                            name="lastname"
                                            class="form-control mb-2"
                                            :placeholder="$t('Vezetéknév')"
                                            v-model="model.billing.lastname"
                                        />
                                        <label for="firstname">{{
                                            $t("Vezetéknév")
                                        }}</label>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div
                                        class="form-floating mb-2"
                                        id="lastname"
                                    >
                                        <input
                                            type="text"
                                            name="firstname"
                                            class="form-control mb-2"
                                            :placeholder="$t('Keresztnév')"
                                            v-model="model.billing.firstname"
                                        />
                                        <label for="lastname">{{
                                            $t("Keresztnév")
                                        }}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-floating mb-2" id="email">
                                <input
                                    type="email"
                                    name="email"
                                    class="form-control mb-2"
                                    :placeholder="$t('E-mail')"
                                    v-model="model.email"
                                />
                                <label for="email">{{ $t("E-mail") }}</label>
                            </div>

                            <div class="form-floating mb-2" id="password">
                                <input
                                    type="password"
                                    name="password"
                                    class="form-control mb-2"
                                    :placeholder="$t('Jelszó')"
                                    v-model="model.password"
                                />
                                <label for="password">{{ $t("Jelszó") }}</label>
                            </div>

                            <div
                                class="form-floating mb-2"
                                id="password-repeat"
                            >
                                <input
                                    type="password"
                                    name="password_confirmation"
                                    class="form-control mb-2"
                                    :placeholder="$t('Jelszó újra')"
                                    v-model="model.password_confirmation"
                                />
                                <label for="password-repeat">{{
                                    $t("Jelszó újra")
                                }}</label>
                            </div>

                            <div class="h3 text-center mt-100 mb-5">
                                {{ $t("Számlázási adatok") }}
                            </div>

                            <div class="form-floating mb-2" id="country">
                                <input
                                    type="text"
                                    name="country"
                                    class="form-control mb-2"
                                    :placeholder="$t('Ország')"
                                    v-model="model.billing.country"
                                />
                                <label for="country">{{ $t("Ország") }}</label>
                            </div>

                            <div class="form-floating mb-2" id="city">
                                <input
                                    type="text"
                                    name="city"
                                    class="form-control mb-2"
                                    :placeholder="$t('Város')"
                                    v-model="model.billing.city"
                                />
                                <label for="city">{{ $t("Város") }}</label>
                            </div>

                            <div class="form-floating mb-2" id="zip">
                                <input
                                    type="text"
                                    name="zip"
                                    class="form-control mb-2"
                                    :placeholder="$t('Irányítószám')"
                                    v-model="model.billing.zip"
                                />
                                <label for="zip">{{
                                    $t("Irányítószám")
                                }}</label>
                            </div>

                            <div class="form-floating mb-2" id="address">
                                <input
                                    type="text"
                                    name="address"
                                    class="form-control mb-2"
                                    :placeholder="$t('Utca, házszám')"
                                    v-model="model.billing.address"
                                />
                                <label for="address">{{
                                    $t("Utca, házszám")
                                }}</label>
                            </div>

                            <div
                                class="company-data"
                                v-bind:class="{
                                    'd-none':
                                        parseInt(model.billing.is_company) === 0
                                }"
                            >
                                <div class="form-floating mb-2" id="company">
                                    <input
                                        type="text"
                                        name="company_name"
                                        class="form-control mb-2"
                                        :placeholder="$t('Cégnév')"
                                        v-model="model.billing.company_name"
                                    />
                                    <label for="company">{{
                                        $t("Cégnév")
                                    }}</label>
                                </div>

                                <div class="form-floating mb-2" id="taxnumber">
                                    <input
                                        type="text"
                                        name="vat_number"
                                        class="form-control mb-2"
                                        :placeholder="$t('Adószám')"
                                        v-model="model.billing.vat_number"
                                    />
                                    <label for="taxnumber">{{
                                        $t("Adószám")
                                    }}</label>
                                </div>
                            </div>

                            <div class="h3 text-center mt-100 mb-5">
                                {{ $t("Szállítás adatok") }}
                            </div>

                            <div class="form-check mb-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    id="shippingbilling"
                                    v-model="model.billing_same_as_shipping"
                                />
                                <label
                                    class="form-check-label"
                                    for="shippingbilling"
                                >
                                    {{
                                        $t(
                                            "A számlázási adataim megegyeznek a szállítási adataimmal"
                                        )
                                    }}
                                </label>
                            </div>

                            <div class="form-floating mb-2" id="country2">
                                <input
                                    type="text"
                                    name="shipping_country"
                                    class="form-control mb-2"
                                    :placeholder="$t('Ország')"
                                    v-model="model.shipping.country"
                                />
                                <label for="country2">{{ $t("Ország") }}</label>
                            </div>

                            <div class="form-floating mb-2" id="city2">
                                <input
                                    type="text"
                                    name="shipping_city"
                                    class="form-control mb-2"
                                    :placeholder="$t('Város')"
                                    v-model="model.shipping.city"
                                />
                                <label for="city2">{{ $t("Város") }}</label>
                            </div>

                            <div class="form-floating mb-2" id="zip2">
                                <input
                                    type="text"
                                    name="shipping_zip"
                                    class="form-control mb-2"
                                    :placeholder="$t('Irányítószám')"
                                    v-model="model.shipping.zip"
                                />
                                <label for="zip2">{{
                                    $t("Irányítószám")
                                }}</label>
                            </div>

                            <div class="form-floating mb-2" id="address2">
                                <input
                                    type="text"
                                    name="shipping_address"
                                    class="form-control mb-2"
                                    :placeholder="$t('Utca, házszám')"
                                    v-model="model.shipping.address"
                                />
                                <label for="address2">{{
                                    $t("Utca, házszám")
                                }}</label>
                            </div>

                            <div class="form-check mt-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    id="newsletter"
                                    v-model="model.newsletter"
                                />
                                <label
                                    class="form-check-label"
                                    for="newsletter"
                                >
                                    <small>{{
                                        $t(
                                            "Feliratkozom a webáruház hírlevelére"
                                        )
                                    }}</small>
                                </label>
                            </div>

                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    id="policies"
                                    v-model="model.aszf"
                                />
                                <label class="form-check-label" for="policies">
                                    <small
                                        >{{ $t("Elfogadom az") }}
                                        <router-link
                                            to="/terms"
                                            target="_blank"
                                            >{{
                                                $t(
                                                    "Általános szerződési feltételeket"
                                                )
                                            }}</router-link
                                        >
                                        {{ $t("és az") }}
                                        <router-link
                                            to="/privacy"
                                            target="_blank"
                                            >{{
                                                $t("Adatkezelési tájékoztatót")
                                            }}</router-link
                                        >.</small
                                    >
                                </label>
                            </div>

                            <div class="text-center mt-5">
                                <button type="submit" class="btn btn-primary">
                                    {{ $t("Regisztrálok") }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Registration",
    data() {
        return {
            model: {
                billing: {
                    is_company: 0
                },
                shipping: {}
            }
        };
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    watch: {
        "model.billing_same_as_shipping": function(val) {
            if (val) {
                this.model.shipping.lastname = this.model.billing.lastname;
                this.model.shipping.firstname = this.model.billing.firstname;
                this.model.shipping.country = this.model.billing.country;
                this.model.shipping.city = this.model.billing.city;
                this.model.shipping.zip = this.model.billing.zip;
                this.model.shipping.address = this.model.billing.address;
            }
        }
    },
    methods: {
        doRegistration() {
            this.$http.post(`${this.url}/register`, this.model).then(() => {
                this.$router.replace({ name: "Home" });
            });
        }
    }
};
</script>

<style scoped></style>
